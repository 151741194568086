import React, { useState } from "react"
import * as styles from "./Services.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Link } from "gatsby"

const Services = ({ strapiData }) => {
  const [displayedCards, setDisplayedCards] = useState(6)

  const handleLoadMore = () => {
    setDisplayedCards(prevCount => prevCount + 3)
  }

  const handleSeeLess = () => {
    setDisplayedCards(6)
  }

  const showSeeLessButton = displayedCards === strapiData?.cards?.length

  return (
    <React.Fragment>
      <div id="usaServices" className={styles.ServicesLocationRefine}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row
            className={`gap-30 justify-content-center ${styles.cardWrapper}`}
          >
            {strapiData?.cards?.slice(0, displayedCards).map((e, i) => (
              <Col xl={4} md={6} xs={12} key={i} className="gap-30 d-flex">
                <div className={styles.card}>
                  <div className=" justify-content-between d-flex">
                    <lottie-player
                      autoplay
                      loop
                      src={e?.image1[0]?.localFile?.publicURL}
                      style={{ height: "60px", width: "60px", margin: "0" }}
                    />
                  </div>

                  <h3>{e?.title}</h3>

                  <p
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>
          {displayedCards < strapiData?.cards?.length ? (
            <div className="text-center">
              <button className={styles.lodaMoreBtn} onClick={handleLoadMore}>
                Load More Services
              </button>
            </div>
          ) : (
            showSeeLessButton && (
              <div className={`text-center ${styles.showlessdiv}`}>
                <Link
                  className={styles.lodalessBtn}
                  onClick={handleSeeLess}
                  to="#usaServices"
                >
                  Show Less Services
                </Link>
              </div>
            )
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Services
