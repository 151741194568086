import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./WebTechStacksUsa.scss"
import * as styles from "./WebTechStacksUsa.module.scss"

const data = [
  {
    title: "Programming Languages",
    tech: [
      {
        name: "Java",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/java_54f81a1404.svg",
      },
      {
        name: "JavaScript",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/javascript_6815655da5.svg",
      },
      {
        name: "React Native",
        icon: "https://invozone-backend.s3.amazonaws.com/react_native_logo_200dceef7d.svg",
      },
      {
        name: "PHP",
        icon: "https://invozone-backend.s3.amazonaws.com/php_5b4ff59f57.svg",
      },
      {
        name: "Python",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/python_e91435e6d1.svg",
      },
      {
        name: "Laravel",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/laravel_b75b3de8d9.svg",
      },
      {
        name: "Go",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/golang_0c41eb4551.svg",
      },
      {
        name: "Elixir",
        icon: "https://invozone-backend.s3.amazonaws.com/elixir_9a4aafe1ac.svg",
      },
      {
        name: "TypeScript",
        icon: "https://invozone-backend.s3.amazonaws.com/typescript_6154a911e5.svg",
      },
    ],
  },
  {
    title: "Frameworks",
    tech: [
      {
        name: "Ionic",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ionic_96aaf85c14.svg",
      },
      {
        name: "NestJs",
        icon: "https://invozone-backend.s3.amazonaws.com/nest_js_0bafededef.svg",
      },
      {
        name: "Node.Js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/node_js_b925a34d58.svg",
      },
      {
        name: "Flutter",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/flutter_8a3e9944e7.svg",
      },
      {
        name: "Vue.js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/vue_js_4dcead6d7d.svg",
      },
      {
        name: "MERN",
        icon: "https://invozone-backend.s3.amazonaws.com/mern_f96cee539a.svg",
      },
      {
        name: "MEAN",
        icon: "https://invozone-backend.s3.amazonaws.com/mean_8cfb7a266e.svg",
      },
      {
        name: "ElectronJS",
        icon: "https://invozone-backend.s3.amazonaws.com/electron_262f92177b.svg",
      },
      {
        name: ".NET core",
        icon: "https://invozone-backend.s3.amazonaws.com/microsoft_net_543a4a8355.svg",
      },
      {
        name: "Ruby on Rails",
        icon: "https://invozone-backend.s3.amazonaws.com/ruby_1_72664ff4ec.svg",
      },
    ],
  },
  {
    title: "Testing",
    tech: [
      {
        name: "Postman",
        icon: "https://invozone-backend.s3.amazonaws.com/postman_f1788df09e.svg",
      },
      {
        name: "Jmeter",
        icon: "https://invozone-backend.s3.amazonaws.com/jmeter_5574325371.svg",
      },
      {
        name: "Selenium",
        icon: "https://invozone-backend.s3.amazonaws.com/selenium_e7216101c3.svg",
      },
      {
        name: "Cypress",
        icon: "https://invozone-backend.s3.amazonaws.com/cypress_d89f4278f9.svg",
      },
      {
        name: "BrowserStack",
        icon: "https://invozone-backend.s3.amazonaws.com/browserstack_8f4893d150.svg",
      },
      {
        name: "Bugzilla",
        icon: "https://invozone-backend.s3.amazonaws.com/bugzilla_8b2affb5bd.svg",
      },
      {
        name: "SoapUI",
        icon: "https://invozone-backend.s3.amazonaws.com/soapui_fde55b50e3.svg",
      },
      {
        name: "Jasmine",
        icon: "https://invozone-backend.s3.amazonaws.com/jasmine_eb03e7a8c4.svg",
      },
      {
        name: "Karma",
        icon: "https://invozone-backend.s3.amazonaws.com/karma_31cd151532.svg",
      },
      {
        name: "Protractor",
        icon: "https://invozone-backend.s3.amazonaws.com/protractor_df67c1a67a.svg",
      },
    ],
  },
  {
    title: "Databases",
    tech: [
      {
        name: "MongoDB",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mongodb_422f7db8a9.svg",
      },
      {
        name: "SQLite",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sqlite_161aa9f09d.svg",
      },
      {
        name: "PostgreSQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postgresql_1b8b78d0c5.svg",
      },
      {
        name: "MySQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mysql_e8443cf676.svg",
      },
    ],
  },
  {
    title: "Content Management System",
    tech: [
      {
        name: "WordPress",
        icon: "https://invozone-backend.s3.amazonaws.com/wordpress_40aceb5a26.svg",
      },
      {
        name: "Joomla",
        icon: "https://invozone-backend.s3.amazonaws.com/joomla_01ef51ff3d.svg",
      },
      {
        name: "Drupal",
        icon: "https://invozone-backend.s3.amazonaws.com/drupal_491b88e8c1.svg",
      },
      {
        name: "Magento",
        icon: "https://invozone-backend.s3.amazonaws.com/magento_255a14cebf.svg",
      },
      {
        name: "Wix",
        icon: "https://invozone-backend.s3.amazonaws.com/wix_52cac9f820.svg",
      },
      {
        name: "Shopify",
        icon: "https://invozone-backend.s3.amazonaws.com/shopify_2634b929db.svg",
      },
    ],
  },
  {
    title: "Devops",
    tech: [
      {
        name: "Kubernetes",
        icon: "https://invozone-backend.s3.amazonaws.com/kubernetes_d5b3b12c3e.svg",
      },
      {
        name: "AWS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_416ddf0f50.svg",
      },
      {
        name: "Docker",
        icon: "https://invozone-backend.s3.amazonaws.com/docker_0c21af2970.svg",
      },
      {
        name: "Google Cloud",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/google_cloud_5079140ead.svg",
      },
      {
        name: "Azure",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/microsoft_azure_8286521de9.svg",
      },
      {
        name: "Jenkins",
        icon: "https://invozone-backend.s3.amazonaws.com/jenkins_c4e3561565.svg",
      },
      {
        name: "CI/CD",
        icon: "https://invozone-backend.s3.amazonaws.com/cicd_67916e961b.svg",
      },
    ],
  },
]

const WebTechStacksUsa = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="webTechStacksUSa">
      <div className={styles.webTechStacksUsaBased}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContent}>
              <div>
                <Nav variant="pills" className={`webTechStacksUSa`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlock">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default WebTechStacksUsa
