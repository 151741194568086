import { Script, graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
import Faqs from "../components/common/Faqs2"
import Banner from "../components/web-application-development-in-usa/Banner"
import Statics from "../components/web-application-development-in-usa/Statics"
import Services from "../components/web-application-development-in-usa/Services"
import Industry from "../components/web-application-development-in-usa/Industry"
import WhyWeb from "../components/web-application-development-in-usa/WhyWeb"
import WebProcess from "../components/web-application-development-in-usa/WebProcess"
import Consultation from "../components/web-application-development-in-usa/Consultation"
import Solutions from "../components/web-application-development-in-usa/Solutions"
import Success from "../components/web-application-development-in-usa/Success"
import SolutionsMobile from "../components/web-application-development-in-usa/SolutionsMobile"
import WebTechStacksUsa from "../components/web-application-development-in-usa/WebTechStacksUsa"
import WebTechStacksUsaMobile from "../components/web-application-development-in-usa/WebTechStacksUsaMobile"

const Webapplicationdevelopment = ({ data }) => {
  //console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const services = data?.strapiPage?.sections[1]
  const industry = data?.strapiPage?.sections[2]
  const whyWeb = data?.strapiPage?.sections[3]
  const tech = data?.strapiPage?.sections[4]
  const process = data?.strapiPage?.sections[5]
  const consult = data?.strapiPage?.sections[6]
  const solutions = data?.strapiPage?.sections[7]
  const success = data?.strapiPage?.sections[8]
  const faqs = data?.strapiPage.sections[9]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <Banner strapiData={heroSection} />
      <Statics />
      <Services strapiData={services} />
      <Industry strapiData={industry} />
      <WhyWeb strapiData={whyWeb} />
      {!isMobile && !isTablet ? (
        <WebTechStacksUsa strapiData={tech} />
      ) : (
        <WebTechStacksUsaMobile strapiData={tech} />
      )}
      <WebProcess strapiData={process} />
      <Consultation strapiData={consult} />
      {!isMobile && !isTablet ? (
        <Solutions strapiData={solutions} />
      ) : (
        <SolutionsMobile strapiData={solutions} />
      )}
      <Success strapiData={success} />
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "location-usa" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Webapplicationdevelopment

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
    </>
  )
}
