// extracted by mini-css-extract-plugin
export var arrowLine = "WebProcess-module--arrowLine--5b8fb";
export var card = "WebProcess-module--card--f0a9f";
export var cardWrapper = "WebProcess-module--cardWrapper--ca138";
export var description = "WebProcess-module--description--811c9";
export var heading = "WebProcess-module--heading--64d27";
export var hoverArrow = "WebProcess-module--hoverArrow--78ee7";
export var hovered = "WebProcess-module--hovered--65a2c";
export var lineNumberDiv = "WebProcess-module--lineNumberDiv--83999";
export var normalArrow = "WebProcess-module--normalArrow--69fa1";
export var number = "WebProcess-module--number--e7f6c";
export var processFlowWebUSA = "WebProcess-module--processFlowWebUSA--0e733";