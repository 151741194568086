// extracted by mini-css-extract-plugin
export var UsaTechStacksMobile = "WebStacksMobileUsa-module--UsaTechStacksMobile--d4a74";
export var cardBg = "WebStacksMobileUsa-module--cardBg--df94b";
export var cards = "WebStacksMobileUsa-module--cards--ed1ba";
export var cardsContent = "WebStacksMobileUsa-module--cardsContent--893ab";
export var description = "WebStacksMobileUsa-module--description--4d413";
export var heading = "WebStacksMobileUsa-module--heading--b0e2d";
export var iconssCard = "WebStacksMobileUsa-module--iconssCard--5f501";
export var nav = "WebStacksMobileUsa-module--nav--108b5";
export var navItem = "WebStacksMobileUsa-module--nav-item--f27f4";
export var navbarBlock = "WebStacksMobileUsa-module--navbarBlock--036bf";
export var tabData = "WebStacksMobileUsa-module--tabData--84c5d";
export var tech = "WebStacksMobileUsa-module--tech--17ffa";
export var techIcon = "WebStacksMobileUsa-module--techIcon--cbb96";
export var techImg = "WebStacksMobileUsa-module--techImg--d6153";
export var technologyIcon = "WebStacksMobileUsa-module--technologyIcon--a74d6";