import React from "react"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import * as styles from "./Consultation.module.scss"

const Consultation = ({ strapiData }) => {
  return (
    <div className={`${styles.bookWebServices}`}>
      <Container className={styles.con}>
        <div className={styles.Ready}>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div className={`${styles.bannerBtnConsult}`}>
            {strapiData?.buttons[0] && (
              <Link
                to={strapiData?.buttons[0]?.url}
                className={styles.btn_white2_border_web_USA}
              >
                {strapiData?.buttons[0]?.title}
              </Link>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Consultation
