// extracted by mini-css-extract-plugin
export var cardBg = "WebTechStacksUsa-module--cardBg--59f70";
export var cards = "WebTechStacksUsa-module--cards--4bba8";
export var cardsContent = "WebTechStacksUsa-module--cardsContent--eee4b";
export var description = "WebTechStacksUsa-module--description--438e3";
export var heading = "WebTechStacksUsa-module--heading--128f9";
export var iconssCard = "WebTechStacksUsa-module--iconssCard--0ed3e";
export var nav = "WebTechStacksUsa-module--nav--ff691";
export var navItem = "WebTechStacksUsa-module--nav-item--a150f";
export var navbarBlock = "WebTechStacksUsa-module--navbarBlock--12ec2";
export var tabData = "WebTechStacksUsa-module--tabData--987b0";
export var tech = "WebTechStacksUsa-module--tech--e20a4";
export var techIcon = "WebTechStacksUsa-module--techIcon--499bf";
export var techImg = "WebTechStacksUsa-module--techImg--fd295";
export var technologyIcon = "WebTechStacksUsa-module--technologyIcon--dd0a9";
export var webTechStacksUsaBased = "WebTechStacksUsa-module--webTechStacksUsaBased--16904";