// extracted by mini-css-extract-plugin
export var WhyCyberWeb = "WhyWeb-module--WhyCyberWeb--16da6";
export var btnWeb = "WhyWeb-module--btnWeb--99d74";
export var cards = "WhyWeb-module--cards--b9368";
export var description = "WhyWeb-module--description--0e75e";
export var hire = "WhyWeb-module--hire--8df23";
export var hireImg = "WhyWeb-module--hireImg--9f282";
export var subTitle = "WhyWeb-module--subTitle--61358";
export var talent = "WhyWeb-module--talent--e17c5";
export var teamBtnWebLocation = "WhyWeb-module--teamBtnWebLocation--08f36";
export var text = "WhyWeb-module--text--1656b";
export var tick = "WhyWeb-module--tick--16550";