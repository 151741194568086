// extracted by mini-css-extract-plugin
export var ServicesLocationRefine = "Services-module--ServicesLocationRefine--64ba9";
export var arrow = "Services-module--arrow--162ff";
export var card = "Services-module--card--54a8f";
export var cardWrapper = "Services-module--cardWrapper--08a3b";
export var description = "Services-module--description--cf651";
export var heading = "Services-module--heading--6cdd2";
export var loader = "Services-module--loader--d4881";
export var lodaMoreBtn = "Services-module--lodaMoreBtn--6c1bc";
export var lodalessBtn = "Services-module--lodalessBtn--9614a";
export var showlessdiv = "Services-module--showlessdiv--a3b08";
export var spin = "Services-module--spin--b846b";
export var trailBg = "Services-module--trailBg--70852";