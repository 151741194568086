// extracted by mini-css-extract-plugin
export var SliderWidth = "Solutions-module--SliderWidth--5f7a1";
export var arrowImg = "Solutions-module--arrowImg--a99d1";
export var bottomContent = "Solutions-module--bottomContent--e474b";
export var bottomHead = "Solutions-module--bottomHead--5318a";
export var bottomPort = "Solutions-module--bottomPort--c644a";
export var bottomPort2 = "Solutions-module--bottomPort2--6274a";
export var bottomTags = "Solutions-module--bottomTags--7ecba";
export var bottomhead1 = "Solutions-module--bottomhead1--c13c2";
export var bottomtag1 = "Solutions-module--bottomtag1--06995";
export var bottomtag2 = "Solutions-module--bottomtag2--e81f6";
export var bottomtag3 = "Solutions-module--bottomtag3--81165";
export var bottomtag3Hover = "Solutions-module--bottomtag3Hover--e8d0f";
export var cardWrapper = "Solutions-module--cardWrapper--18d06";
export var cards = "Solutions-module--cards--73224";
export var description = "Solutions-module--description--fc803";
export var heading = "Solutions-module--heading--6cff5";
export var headingPremium = "Solutions-module--headingPremium--a6103";
export var mainWorkSolutionsWeb = "Solutions-module--mainWorkSolutionsWeb--e183e";
export var portfolioArrowIcon = "Solutions-module--portfolioArrowIcon--8064c";
export var portfolioArrowIconCover = "Solutions-module--portfolioArrowIconCover--223cc";
export var portfolioArrowRightIconCover = "Solutions-module--portfolioArrowRightIconCover--58742";
export var portfolioLinkImage = "Solutions-module--portfolioLinkImage--4e581";
export var premiumImg = "Solutions-module--premiumImg--80fdd";
export var project2 = "Solutions-module--project2--c3cae";
export var projectsAll = "Solutions-module--projectsAll--b8052";
export var providingImg = "Solutions-module--providingImg--1790d";
export var reactJsIconContainer = "Solutions-module--reactJsIconContainer--3b23d";
export var reactJsIconContainerLeft = "Solutions-module--reactJsIconContainerLeft--49b8b";
export var teamButton = "Solutions-module--teamButton--69f86";
export var trailBg = "Solutions-module--trailBg--79184";