// extracted by mini-css-extract-plugin
export var SolutionsMobileClients = "SolutionsMobile-module--SolutionsMobileClients--155fe";
export var bottomContent = "SolutionsMobile-module--bottomContent--8ab8c";
export var bottomHead = "SolutionsMobile-module--bottomHead--6d6f2";
export var bottomPort = "SolutionsMobile-module--bottomPort--ca24d";
export var bottomPort2 = "SolutionsMobile-module--bottomPort2--d54c4";
export var bottomTags = "SolutionsMobile-module--bottomTags--908bd";
export var bottomhead1 = "SolutionsMobile-module--bottomhead1--2afcd";
export var bottomtag1 = "SolutionsMobile-module--bottomtag1--549ab";
export var bottomtag2 = "SolutionsMobile-module--bottomtag2--5a0d2";
export var bottomtag3 = "SolutionsMobile-module--bottomtag3--5ebec";
export var bottomtag3Hover = "SolutionsMobile-module--bottomtag3Hover--ec676";
export var cardWrapper = "SolutionsMobile-module--cardWrapper--ed55a";
export var caseStudiesBtnPortfolio = "SolutionsMobile-module--caseStudiesBtnPortfolio--50338";
export var description = "SolutionsMobile-module--description--6c78b";
export var firstMobileImagaPort = "SolutionsMobile-module--firstMobileImagaPort--8dc63";
export var firstPortBottom = "SolutionsMobile-module--firstPortBottom--ec1fd";
export var firstPortFolio = "SolutionsMobile-module--firstPortFolio--9a617";
export var head = "SolutionsMobile-module--head--57826";
export var heading = "SolutionsMobile-module--heading--27f93";
export var hoverFirstPortImg = "SolutionsMobile-module--hoverFirstPortImg--bd1bd";
export var normalrFirstPortImg = "SolutionsMobile-module--normalrFirstPortImg--b3e45";
export var portImage = "SolutionsMobile-module--portImage--06a5f";
export var portfolioLinkImage = "SolutionsMobile-module--portfolioLinkImage--60d4e";
export var project2 = "SolutionsMobile-module--project2--b30ca";
export var projectsAll = "SolutionsMobile-module--projectsAll--ae412";
export var tag1 = "SolutionsMobile-module--tag1--3a650";
export var tag2 = "SolutionsMobile-module--tag2--ee3d9";
export var tag3 = "SolutionsMobile-module--tag3--5ebf4";
export var tags = "SolutionsMobile-module--tags--51b48";