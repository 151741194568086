import { Link } from "gatsby"
import React from "react"
import * as styles from "./Banner.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const Banner = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.heroBanner}>
        <Container>
          <div className={styles.ring}>
            <div className={styles.ball_holder}>
              <div className={styles.ball}></div>
            </div>
          </div>
          <Row>
            <Col xs={12}>
              <p
                className={styles.bannerSubtitle}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <h1
                className={styles.bannerHeading}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <p
                className={styles.bannerSubtitle2}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle2,
                }}
              />
              <div className={`${styles.bannerBtnLocation}`}>
                {strapiData?.buttons[0] && (
                  <Link
                    to={strapiData?.buttons[0]?.url}
                    className={styles.getBtnLocation}
                  >
                    {strapiData?.buttons[0]?.title}
                  </Link>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Banner
