// extracted by mini-css-extract-plugin
export var Ready = "Consultation-module--Ready--168e5";
export var bannerBtnConsult = "Consultation-module--bannerBtnConsult--d45d8";
export var bookWebServices = "Consultation-module--bookWebServices--2ec5c";
export var btn_white2_border_web_USA = "Consultation-module--btn_white2_border_web_USA--284a7";
export var card = "Consultation-module--card--f14cb";
export var cardWrapper = "Consultation-module--cardWrapper--16892";
export var con = "Consultation-module--con--37332";
export var description = "Consultation-module--description--64b58";
export var description2 = "Consultation-module--description2--d9f7b";
export var heading = "Consultation-module--heading--a3745";